// Custom Theming for Angular Material
@use "@angular/material" as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
//Tailwindcss imports for Angular
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "tailwindcss/screens";
@include mat.core();

@font-face {
  font-family: "ProximaNova-Thin";
  src: url(/assets/fonts/PN-Thin.otf) format("opentype");
}

@font-face {
  font-family: "ProximaNova-Thin-Italic";
  src: url(/assets/fonts/PN-Thin-Italic.otf) format("opentype");
}

@font-face {
  font-family: "ProximaNova-Light";
  src: url(/assets/fonts/PN-Light.otf) format("opentype");
}

@font-face {
  font-family: "ProximaNova-Light-Italic";
  src: url(/assets/fonts/PN-Light-Italic.otf) format("opentype");
}

@font-face {
  font-family: "ProximaNova-Regular";
  src: url(/assets/fonts/PN-Regular.otf) format("opentype");
}

@font-face {
  font-family: "ProximaNova-Regular-Italic";
  src: url(/assets/fonts/PN-Regular-Italic.otf) format("opentype");
}

@font-face {
  font-family: "ProximaNova-Semibold";
  src: url(/assets/fonts/PN-Semibold.otf) format("opentype");
}

@font-face {
  font-family: "ProximaNova-Semibold-Italic";
  src: url(/assets/fonts/PN-Semibold-Italic.otf) format("opentype");
}

@font-face {
  font-family: "ProximaNova-Bold";
  src: url(/assets/fonts/PN-Bold.otf) format("opentype");
}

@font-face {
  font-family: "ProximaNova-Bold-Italic";
  src: url(/assets/fonts/PN-Bold-Italic.otf) format("opentype");
}

@layer base {
  html {
    font-family: ProximaNova-Regular, system-ui, sans-serif;
  }
}

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$md-grata: (
  50: #e8f5ff,
  100: #c5e5fe,
  200: #9ed4fe,
  300: #77c3fd,
  400: #5ab6fc,
  500: #3da9fc,
  600: #37a2fc,
  700: #2f98fb,
  800: #278ffb,
  900: #1a7efa,
  A100: #ffffff,
  A200: #f9fcff,
  A400: #c6deff,
  A700: #adcfff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$web-primary: mat.define-palette($md-grata);
$web-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$web-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$web-theme: mat.define-light-theme($web-primary, $web-accent, $web-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($web-theme);

html,
body {
  height: 100%;
}
body {
  margin: 0;
  h1 {
    color: #5f6c7b;
    font-weight: 600;
    line-height: 32px;
  }
  p,
  h3,
  span,
  label,
  input,
  div,
  button {
    color: #5f6c7b;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
  }
}
/* You can add global styles to this file, and also import other style files */
// @import "normalize";
// @import "main";

/*.mat-tab-link-container {
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
}*/
.mat-table {
  width: 100%;
}


app-resume {
  table {
    width: 100%;
  }
}

.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-24 {
  font-size: 24px;
}

:host ::ng-deep.mat-paginator .mat-paginator-page-size{
  justify-content: center;
}

